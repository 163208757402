.contact{
    margin: auto;
    width: 80%;
    padding: 1rem;
    color: #fff;
    display: flex;
    flex-direction: column;
   
}
.contact h1{
    font-size: 3rem;
    text-align: center;
}
.contact-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0 3rem;
}
.contact-left{
    flex: 1;
   
}
.contact-left img{
    width: 500px;
    height: 500px;
    object-fit: contain;
    border-radius: 10px;
}
.contact-right{
    flex: 1;
   
}
.contact-right form{
    display: flex;
    flex-direction: column;
    width: 400px;
    max-width: 400px;
    height: auto;
    margin: auto;
    padding: 1rem 0;
}
.contact-right form >label{
    padding: 1rem 0;
    font-size: 1.2rem;
    font-weight: 700;
}
.contact-right form>input{
    padding: 10px 20px;
    outline: none;
    border: 0;
    border-bottom: 2px solid #fff;
    background-color: transparent;
    font-size: 1.2rem;
    color: #fff;
}
.contact-right form >textarea{
    margin: 1rem 0;
    outline: none;
    overflow: hidden;
    height: 100px;
    
}
.contact-right form>button{
    width: 100px;
    padding: 10px 20px;
    margin: auto;
    text-align: center;
    align-items: center;
    outline: none;
    border: none;
    background-color: orangered;
    color: #fff;
    transition: 1s ease;
    cursor: pointer;
    border-radius: 10px;
    font-size: 1.2rem;
    font-weight: 700;
}
.contact-right form>button:hover{
    background-color: #fff;
    color: #000;

}

@media screen and (max-width:375px){
    .contact-container{
        display: flex;
        align-items: center;
        justify-content: center;
        padding:3rem;
        width: 80%;    
    }
    
    .contact-right form{
        width: 300px;
        margin-left: 3rem;
    
    }
    
}