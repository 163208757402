.footer{
    width: 100%;
    margin: auto;
    color: #fff;
    position: relative;
}
.footer hr{
    background-color: #fff;

}
.footer-container ul {
    width: 80%;
    margin: auto;
    display:flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    position: relative;
    
}
.footer-container ul li{
    margin: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    
}
.footer-container i{
 color: aqua;
 flex-direction: column;
 padding: 1rem;
 align-items: center;
 justify-content: center;
 font-size: 2rem;
}
.footer-container a{
    color:white;
    font-size:1.2rem;
    text-decoration: none;
    font-weight: 600;
}
.link{
    padding:1rem;
    background-color: #3c3c3c;
    z-index: 20;
    
}
.link-container{
    width: 80%;
    margin: 0 auto;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    
}
.link a{
    
   margin: 0 1rem;
    color:#fff ;
    font-size: 1rem;
    text-decoration: none;
   
}
.link i{
    color: aquamarine;
    font-size: 1.3rem;
    margin: 0 1rem;
    color: #ccc;
    
}
.blur-footer-l{
    width: 300px;
    height: 300px;
    border-radius: 50%;
    background-color: rgb(250, 193, 89);
    filter: blur(142px);
    z-index: -9;
    margin-right:5rem;
}
.blur-footer-r{
    width: 300px;
    height: 300px;
    border-radius: 50%;
    background-color: rgb(233, 129, 43);
    filter: blur(142px);
    z-index: -9;
    margin-right:5rem;
}
.blur-container{
    position: absolute;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    
}

@media screen and (max-width:375px){
    .footer-container ul li{
        margin:1rem;
    }
    .link i{
        margin: 1rem 1rem;
    }
    
}